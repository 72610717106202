.contact {
  padding: 0 10% 0 10%;
}

.contact-header {
  text-align: center;
  line-height: 0px;
  font-size: 50px;
  margin-bottom: 4rem;
}

.contact-paragraph {
  font-size: 19px;
}

.contact-information {
  margin: 0;
  font-size: 19px;
}

.contact-information a {
  text-decoration: none;
  color: black;
}

.contact-information a:hover {
  color: #088f8f;
}
