.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 2rem 0.5rem 2rem;
  margin-top: auto;
}

footer p {
  font-size: 16px;
  padding-right: 2rem;
}

.footer-links {
  align-self: center;
}

.footer-links img {
  height: 20px;
}

.muckrack-logo,
.x-logo {
  margin-left: 2.5rem;
}

.x-logo img {
  height: 17px;
  margin-bottom: 2px;
}

.muckrack-logo {
  color: black;
}

@media (width < 377px) {
  .footer {
    align-items: center;
    flex-direction: column;
  }

  footer p {
    order: 2;
    padding-right: 2px;
  }

  .footer-links {
    padding-top: 1rem;
  }
}
