.reporting-wrapper {
  padding: 0 1rem;
}

.reporting-header-section {
  text-align: center;
  margin-bottom: 7rem;
  line-height: 0;

  h1 {
    font-size: 50px;
    margin-bottom: 4rem;
  }
  h2 {
    font-size: 19px;
    font-weight: 400;
  }
}

.card-section {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.card {
  border: white 2px solid;
}

.card:hover {
  border: black 2px solid;
}

figure {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1.6 / 1;
  margin-left: auto;
  display: block;
}

.card a {
  text-decoration: none;
  color: black;
}

.information-wrapper {
  padding: 1rem;
  flex: 3;
}

.publication-date {
  margin: 0;
  color: grey;
}

@media (width < 1000px) {
  .card-section {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (width < 700px) {
  .card-section {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width < 500px) {
  .card-section {
    grid-template-columns: 1fr;
  }
}
